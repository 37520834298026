import request from '@/utils/http'
/**
 * 订阅消息
 * @param payload
 */
 export function wxapp_message_lists(payload) {
    return request({ url: '/client/WxappTemplate/lists', method: 'get', data: payload })
}

/**
 * 模板开启
 * @param payload
 */
 export function wxapp_message_open(payload){
    return request({ url: '/client/WxappTemplate/open', method: 'post', data: payload })
}

/**
 * 模板test
 * @param payload
 */
 export function wxapp_message_test(payload){
    return request({ url: '/client/WxappTemplate/test', method: 'post', data: payload })
}